<template>
  <div class="ml-5 mr-5">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    <div class="text-right mb-4">
            <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Mailing Type</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addMailingData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-container class="px-50" fluid>
                <v-text-field
                  v-model="addMailing.mailingName"
                  label="Mailing Type"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
              </v-container>
              <v-container class="px-50" fluid> </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="error" v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog2 = false"
                    @click="deleteMailType"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Record(s) to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>

    </div>
    </div>
    <div class="text-right"></div>

 <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox">
              <v-checkbox
                ref="n"
                v-model="allSelected"
                @click="selectAll"
                v-show="checkIfOperationExistForModule('delete')"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :items="mailing_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-model="deleteItems"
                  :value="item.mailing_id"
                  :key="item.mailing_id"
                  @click="addToDelete($event, item.mailing_id)"
                  v-show="checkIfOperationExistForModule('delete')"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                      medium
                      color="grey"
                      @click="addToUpdate($event, item.mailing_id)"
                      v-show="checkIfOperationExistForModule('edit')"
                    >
                      mdi-pencil
                    </v-icon>
              </template>
            </v-data-table>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
	  <v-card>
                    <v-card-title>
                      <span class="headline">Update Mailing Type</span>
                    </v-card-title>
                    <v-form ref="form" @submit="updateMailingData" method="put">
                      <v-container class="px-50" fluid>
                        <v-text-field
                          v-model="updateMailing.mailingName"
                          label="Mailing Type"
                          :rules="nameRules"
                          required
                        ></v-text-field>
                        <div style="color: red">
                          {{ upmessage1 }}
                        </div>
                      </v-container>
                      <v-container class="px-50" fluid> </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          @click="updateMailingData"
                          type="submit"
                        >
                          Update
                        </v-btn>
                        <v-btn class="close_button mx-2" text @click="toggleUpdateModal = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
	  </template>
     </v-dialog>
</v-row>
        <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Mailing Type",
          value: "mailing_name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      valid: true,
      checkbox: false,
      isLoading: true,
      fullPage: true,
      mailingName: [],
      mailing_data: [],
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_mailingId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      addMailing: {
        mailingName: null,
      },
      updateMailing: {
        mailingId: null,
        mailingName: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
    };
  },

  methods: {
     checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getMailing() {
      let check = this;
      const path = this.$url("MAILING");
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check  })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data.payload);
          this.mailingName = res.data.payload.mtypeId;
          this.total = this.mailingName.length;
          if (Array.isArray(this.mailingName) && this.mailingName.length) {
            this.mailing_data = [];
            let i;
            for (i = 0; i < this.mailingName.length; i++) {
              let tempobj = {
                mailing_id: this.mailingName[i][0],
                mailing_name: this.mailingName[i][1],
              };
              this.mailing_data.push(tempobj);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addMailingData(e) {
      e.preventDefault();
      let check = this;
      check.isLoading = true
      if (
        this.addMailing.mailingName != null &&
        this.addMailing.mailingName != ""
      ) {
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: check })
          .post(this.$url("MAILING"), this.addMailing)
          .then((result) => {
            check.isLoading = false
            this.addMailing.mailingName = null;
            console.log(result.data.payload);
            this.message1 = result.data.payload[0].mTypeID;
            this.message2 = result.data.payload[0].mTypeName;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].mTypeID;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.getMailing();
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
              }
              this.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           check.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true
      view.mailingName.forEach(function (o, i) {
        if (o[0] == atchid) {
          view.updateMailing.mailingName = o[1];
          view.updateMailing.mailingId = o[0];
        }
      });
    },
    updateMailingData(e) {
      e.preventDefault();
      let data = this;
      data.isLoading = true
      if (
        data.updateMailing.mailingName != null &&
        data.updateMailing.mailingName != ""
      ) {
        this.$fetch({ requiresAuth: true, operation: 'edit', vueScope: data  })
          .put(data.$url("MAILING"), data.updateMailing)
          .then((result) => {
            data.isLoading = false
            this.upmessage1 = result.data.payload.mTypeID;
            this.upmessage2 = result.data.payload.mTypeName;
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.mTypeID;
              this.dialog1 = true;
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200 && this.upmessage1 == null) {
                this.getMailing();
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully",
                  text: result.data.payload.message,
                });
                this.toggleUpdateModal = false
              }
              this.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           data.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_mailingId.indexOf(e.target.value) == -1) {
          this.checked_mailingId.push(e.target.value);
        }
      } else {
        this.checked_mailingId.splice(
          this.checked_mailingId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_mailingId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteMailType(e) {
      e.preventDefault();
      let check = this;
      check.isLoading = true
      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: check })
        .delete(this.$url("MAILING"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          check.isLoading = false
          if (response.data.statusCode == 200) {
            check.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          this.getMailing();
          this.deleteItems = [];
        }).catch((error) => {
           console.error(error);
           check.isLoading = false
           check.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
    },
    selectAll: function () {
      let mailing = this.mailingName;
      if (this.allSelected == true) {
        if (Array.isArray(mailing) && mailing.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < mailing.length; i++) {
            console.log(mailing[i]);
            this.deleteItems.push(mailing[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
    watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
 if(o){
 this.getMailing(1);
 }
 },
 },
};
</script>
